<template>
  <div class="login">
    <div class="content">
      <img
        v-show="!isWechatWay"
        class="switch"
        @click="switchLogin"
        src="./qrcode.png"
        alt=""
      />
      <img
        v-show="isWechatWay"
        class="switch"
        @click="switchLogin"
        src="./normal.png"
        alt=""
      />
      <!-- <span class="logoText">Cartier KOL System</span> -->
      <div class="login_content">
        <!-- <el-tabs v-model="loginWay">
            <el-tab-pane label="账号密码登录" name="account"> -->
        <div class="box">
          <div v-show="!isWechatWay">
            <span class="logoText">Meritco omniVoice</span>
            <p v-show="needBindAccount" class="spanTips">
              <svg-icon class-name="question" />
              请输入您的需要绑定的账号和密码
            </p>
            <el-form
              :model="ruleForm"
              status-icon
              :rules="rules"
              ref="ruleForm"
            >
              <el-form-item prop="username">
                <el-input
                  type="text"
                  placeholder="请输入用户名"
                  v-model="ruleForm.username"
                >
                  <template #prefix>
                    <svg-icon class="ml5" className="account" />
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  type="password"
                  placeholder="请输入密码"
                  v-model="ruleForm.password"
                >
                  <template #prefix>
                    <svg-icon class="ml5" className="password" />
                  </template>
                </el-input>
              </el-form-item>
              <p class="errorMessage" v-show="errorMessageShow">
                {{ errorMessage[errorCode] }}
              </p>
              <el-form-item>
                <el-button
                  class="submitBtn"
                  type="primary"
                  @click="submitForm('ruleForm')"
                >
                  登录
                </el-button>
              </el-form-item>
            </el-form>
          </div>
          <div v-show="isWechatWay" id="wechatQrcode" class="wechatDiv"></div>
        </div>
        <!-- </el-tab-pane>
            <el-tab-pane label="微信扫码登录" name="wechat"> -->
        <!-- <div v-else id="wechatQrcode" class="wechatDiv"></div> -->
        <!-- </el-tab-pane> -->
        <!-- </el-tabs> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/api/login";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "Login",
  data() {
    let validateAccount = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入用户名"));
      } else {
        callback();
      }
    };
    let validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            validator: validateAccount,
            trigger: "blur",
          },
        ],
        password: [
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
      },
      lockedTime: "4",

      errorCode: 0,
      errorMessageShow: false,
      // account:账号密码    wechat:微信扫码
      // loginWay: "account",
      // false:正常使用    true:绑定账号
      needBindAccount: false,
      tabs_header: null,
      el_tabs: null,
      isWechatWay: false
    };
  },
  computed: {
    ...mapGetters(["appid", "redirectUrl", "refreshToken"]),
    errorMessage() {
      return {
        0: "用户名或密码错误",
        1: "用户名或密码错误",
        2: "不允许的IP地址",
        3: "超过最大登录数",
        4: "用户已过期",
        5: "用户已禁用",
        6: "请先微信扫码认证",
        7: "已超过允许绑定的微信数量",
        8: "未分配可查看人群",
        10: "获取微信信息失败",
        20: "请微信扫码登录",
        21: "系统升级维护中",
        1019: `3次输入错误，账号被锁定，${this.lockedTime}分钟后解锁`,
      };
    },
  },



  mounted() {
    // this.tabs_header = document.querySelector(".el-tabs__header");
    // this.el_tabs = document.querySelector(".el-tabs");
    // this.tabs_header.classList.remove("bindStyle");
    // this.el_tabs.classList.remove("addPadding");
    this.createWechat();
    this.judgeLoginWay();
    this.enterSubmit();
  },
  methods: {
    ...mapActions(["logInAction"]),
    ...mapMutations({
      setToken: "SET_TOKEN",
      setRefreshToken: "SET_REFRESH_TOKEN",
      setUpdateText: "SET_UPDATETEXT",
    }),
    switchLogin() {
      this.isWechatWay = !this.isWechatWay
    },
    // 创建微信对象以及重定向
    createWechat() {
      // eslint-disable-next-line no-undef
      new WxLogin({
        id: "wechatQrcode",
        appid: this.appid,
        scope: "snsapi_login",
        // state: 'aaa',
        // 重定向url，需要进行encode
        redirect_uri: encodeURIComponent(this.redirectUrl),
        href: "data:text/css;base64,Ci5pbXBvd2VyQm94IC50aXRsZSB7CiAgZGlzcGxheTogbm9uZTsKfQoKLmltcG93ZXJCb3ggLnFyY29kZSB7CiAgd2lkdGg6IDIwMHB4OwogIG1hcmdpbi10b3A6IDA7Cn0KCi5pbXBvd2VyQm94IC5pbmZvIHsKICB3aWR0aDogMjEwcHg7Cn0KCi5pbXBvd2VyQm94IC5zdGF0dXMgewogIHRleHQtYWxpZ246IGNlbnRlcjsKfQo=",
      });
    },
    judgeLoginWay() {
      this.loginWay = "account";
      if (this.refreshToken) {
        // 有报错信息
        if (this.refreshToken.message) {
          this.errorCode = this.refreshToken.message;
          this.errorMessageShow = true;
        } else {
          // 微信没有权限，需要绑定账号
          this.needBindAccount = true;
          this.tabs_header.classList.add("bindStyle");
          this.el_tabs.classList.add("addPadding");
          // 将refresh_token跟随form表单提交
          this.ruleForm.refresh_token = this.refreshToken;
        }
      }
    },
    enterSubmit() {
      document.onkeyup = (event) => {
        if (event.keyCode === 13 && this.$route.path === "/homeIndex") {
          this.submitForm("ruleForm");
        }
      };
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getToken();
        } else {
          return false;
        }
      });
    },
    async getToken() {
      const params = Object.assign({}, this.ruleForm);
      params.password = this.sha256(params.password);
      const { code, data, msg } = await getToken(params);
      if (data) {
        // 供应商登录时记录供应商名称
        this.$storage.setItem("agencyName", this.ruleForm.username);
        this.errorMessageShow = false;
        this.setRefreshToken("");
        this.setToken(data);
        await this.logInAction();
      } else {
        if (code === 21) {
          this.setUpdateText(msg);
          await this.$router.push("/updatePage");
        } else {
          this.errorCode = code;
          this.lockedTime = msg.charAt(msg.length - 1);
          this.errorMessageShow = true;
        }
      }
    },
  },
  watch: {
    errorMessageShow(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.errorMessageShow = false;
        }, 5000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f0f2f5 url(bgImg.7e7d1ad.jpg) no-repeat fixed 50%;

  .logoText {
    width: 100%;
    text-align: center;
    display: inline-block;
    margin-bottom: 30px;
    margin-top: 10px;
    height: 40px;
    line-height: 40px;
    font-size: 30px;
    color: #3a85f1;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -77.5%);
    padding: 0 65px;
    width: 520px;
    height: 420px;

    .switch {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }
}
.wechatDiv {
  height: 282px;
  text-align: center;
}
.login_content {
  padding-top: 60px;
  height: 370px;
  // padding: 0 10px;
  // margin-top: -25px;

  .submitBtn {
    width: 390px;
    height: 45px;
  }
}
</style>

<style lang="scss">
.login_content {
  .el-tabs__item.is-top {
    width: 185px;
    text-align: center;
    font-size: 16px;
  }

  .el-input--prefix .el-input__inner {
    height: 45px;
  }

  .errorMessage {
    position: relative;
    top: -10px;
    color: #f56c6c;
  }

  .spanTips {
    margin-bottom: 10px;
    color: #aaa;
  }

  .bindStyle {
    display: none;
  }

  .addPadding {
    padding-top: 35px;
  }
}
</style>
